var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('div',{staticStyle:{"margin-top":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.title))])]),(!this.isOrganization)?_c('new-proposal',{on:{"done":_vm.fetch}}):_vm._e(),(this.isOrganization && !!this.organization)?_c('div',[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.organization.name))]),_c('div',[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":"","color":"primary lighten-1"}},[_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('Available DTOs'))+":")]),_c('v-simple-table',{staticStyle:{"font-size":"12px","width":"auto"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.organization.available_dtos_by_type),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(_vm.innerParseFloat(value)))])])}),0)]},proxy:true}],null,false,1803821472)})],1)],1)]):_vm._e()],1),_c('div',{staticClass:"ml-4"},[_c('p',[_vm._v(_vm._s(_vm.$t('My Proposals Description')))])]),_c('v-divider'),_c('v-data-table',{staticClass:"striped cursor-pointer",attrs:{"loading":_vm.loading,"items":_vm.proposals,"headers":_vm.headers,"footer-props":{ 'items-per-page-options': [10, 20, 50, -1]},"no-data-text":_vm.$t('You have no proposals yet'),"options":_vm.options,"server-items-length":_vm.totalItems,"items-per-page":_vm.itemsPerPage},on:{"click:row":function (item) { return _vm.$router.push({name: 'proposal-details', params: { id: item.id, isOrganization: this$1.isOrganization }}); },"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mt-5 font-weight-bold"},[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.justification))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('status-chip',{attrs:{"value":item.status,"kind":"proposal"}})]}},{key:"item.dto",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.innerParseFloat(_vm.sumValues(item.assigned_dtos_by_type)))+" | "+_vm._s(_vm.innerParseFloat(_vm.sumValues(item.pending_dtos_by_type)))+" | "+_vm._s(_vm.innerParseFloat(_vm.sumValues(item.consumed_dtos_by_type))))])]}}],null,true)},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('span',{},[_vm._v(_vm._s(_vm.$t('DTO status')))]),_vm._l((item.all_keys),function(key){return _c('ul',{key:key,staticClass:"d-flex flex-column align-center pa-0 ma-0",staticStyle:{"list-style":"none"}},[_c('li',{},[_vm._v(" "+_vm._s(key)+": "+_vm._s(isNaN(_vm.innerParseFloat(item.assigned_dtos_by_type[key])) ? '0' : _vm.innerParseFloat(item.assigned_dtos_by_type[key]))+" | "+_vm._s(isNaN(_vm.innerParseFloat(item.pending_dtos_by_type[key])) ? '0' : _vm.innerParseFloat(item.pending_dtos_by_type[key]))+" | "+_vm._s(isNaN(_vm.innerParseFloat(item.consumed_dtos_by_type[key])) ? '0' : _vm.innerParseFloat(item.consumed_dtos_by_type[key]))+" ")])])})],2)])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.created), 'short'))+" ")]}},_vm._l((_vm.$scopedSlots),function(_,scopedSlotName){return {key:scopedSlotName,fn:function(slotData){return [_vm._t(scopedSlotName,null,null,slotData)]}}}),{key:"footer.prepend",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"secondary","icon":""},on:{"click":_vm.fetch}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }