<template>
  <v-dialog v-model="dialog" width="400">
    <template #activator="{ on: dialog }">
      <slot name="activator" :on="dialog" />
    </template>
    <v-card :loading="loading">
      <v-card-title class="text-h5 mb-5">{{ $t("Freeze proposal") }}</v-card-title>
      <v-card-text>
        {{ $t("Freeze Proposal Description") }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="submit">{{ $t('Freeze Proposal') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "FreezeProposal",
  props: [ "obj" ],
  data: function() {
    return {
      dialog: false,
      loading: false,
    }
  },
  methods: {
    submit() {
      this.loading = true;
      this.$apiRequest('post', `proposals/${this.obj.id}/close/`).then(() => {
        this.$store.dispatch('alert/alert', {
          type: "info",
          message: this.$t("Proposal succesfully closed")
        });
        this.loading = false;
        this.dialog = false;
        this.$emit("done");
      })
    }
  },
}
</script>

<style scoped>
</style>
