<template>
  <v-card elevation="0">
    <v-card-title class="d-flex justify-space-between">
      <div style="margin-top: auto;">
        <span>{{ title }}</span>
      </div>
      <new-proposal @done="fetch" v-if="!this.isOrganization"/>
      <div v-if="this.isOrganization && !!this.organization" >
        <span style="font-size: 12px;">{{ organization.name }}</span>
        <div>
          <v-card outlined color="primary lighten-1" class="pa-2">
            <span style="margin-right: 10px;">{{ $t('Available DTOs') }}:</span>
            <v-simple-table style="font-size: 12px; width: auto;">
              <template v-slot:default>
          <tbody>
            <tr v-for="(value, key) in organization.available_dtos_by_type" :key="key">
              <td>{{ key }}</td>
              <td>{{ innerParseFloat(value) }}</td>
            </tr>
          </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </div>
      </div>
      
    </v-card-title>
    <div class="ml-4">
        <p>{{ $t('My Proposals Description') }}</p>
      </div>
    <v-divider />
    <v-data-table :loading="loading" :items="proposals" class="striped cursor-pointer" :headers="headers"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, -1]}"
      :no-data-text="$t('You have no proposals yet')"
      @click:row="(item) => $router.push({name: 'proposal-details', params: { id: item.id, isOrganization: this.isOrganization }})"
      :options.sync="options" :server-items-length="totalItems" :items-per-page="itemsPerPage" >
      <template  #[`item.title`]="{ item }">
        <div class="mt-5 font-weight-bold">{{ item.title }}</div>
        <p>{{ item.justification }}</p>
      </template>
      <template #[`item.status`]="{ item }">
        <status-chip :value="item.status" kind="proposal" />
      </template>
      <template #[`item.dto`]="{ item }">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ innerParseFloat(sumValues(item.assigned_dtos_by_type)) }} | {{ innerParseFloat(sumValues(item.pending_dtos_by_type)) }} | {{ innerParseFloat(sumValues(item.consumed_dtos_by_type)) }}</span>
          </template>
          <div class="d-flex flex-column align-center">
            <span class="">{{ $t('DTO status') }}</span>
            <ul v-for="key in item.all_keys" :key="key" class="d-flex flex-column align-center pa-0 ma-0" style="list-style: none;">
              <li class="">
                {{ key }}: 
                {{ isNaN(innerParseFloat(item.assigned_dtos_by_type[key])) ? '0' : innerParseFloat(item.assigned_dtos_by_type[key]) }}
                | 
                {{ isNaN(innerParseFloat(item.pending_dtos_by_type[key])) ? '0' : innerParseFloat(item.pending_dtos_by_type[key]) }}
                | 
                {{ isNaN(innerParseFloat(item.consumed_dtos_by_type[key])) ? '0' : innerParseFloat(item.consumed_dtos_by_type[key]) }}
              </li>
            </ul>
          </div>
           
         </v-tooltip>
      </template>
      <template #[`item.created`]="{ item }">
        {{ $d(new Date(item.created), 'short') }}
      </template>
      <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template #[`footer.prepend`]>
        <v-btn small color="secondary" icon @click="fetch"><v-icon small>mdi-refresh</v-icon></v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import NewProposal from "./NewProposal.vue"
import StatusChip from '@/components/utils/StatusChip.vue';
import {formatter, sumValues} from '@/helpers/utils.js'

export default {
  name: "MyProposals",
  components: { NewProposal, StatusChip },
  props: {
    title: {
      type: String,
    },
    endPoint: {
      type: String,
    },
    isOrganization: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      groupBy: [
          {
            key: 'description',
            order: 'asc',
          },
        ],
      proposals: [],
      headers: [
        { value: 'id', text: '#', align: 'center', sortable: true, divider: true, width: 80, },
        { value: 'title', text: this.$i18n.t('Description'), align: 'left', sortable: true },
        { value: 'status', text: this.$i18n.t('Status'), align: 'center', sortable: true }, 
        { value: 'dto', text: this.$i18n.t('DTOs'), align: 'center', sortable: false },
        { value: 'created', text: this.$i18n.t('Created'), align: 'right', sortable: false },
      ],
      loading: false,
      totalItems: 0,
      itemsPerPage: 50,
      lastFetchTime: null,
      selectedItem: null,
      options: {},
      organization: null,
    }
  },
  computed: {
    filterset() {
      let orderings = [];
      if (this.options.sortBy) {
        for (var i=0;i<this.options.sortBy.length;i++) {
          orderings.push((this.options.sortDesc[i]?'':'-') + this.options.sortBy[i]);
        }
      if (orderings.length == 0) {orderings = ["-status"]}
      }
      return {
        ...this.filters,
        ordering: orderings.join(","),
        page: this.options.page,
        page_size: this.options.itemsPerPage,
      }
    }
  },
  mounted() {
    if(this.isOrganization){
      this.loadOrganization();
    }
  },
  methods: {
    sumValues,
    fetch() {
      this.loading = true;
      var thisFetchTime = this.lastFetchTime = Date.now()
      this.$apiRequest('get', this.endPoint, {
        params: this.filterset,
      }).then(res => {
        if (this.lastFetchTime <= thisFetchTime) {
          this.proposals = res.data.results;
          this.totalItems = res.data.count;
          this.itemsPerPage = res.data.results.length;
          this.$apiRequest('get', "proposals/associated/", {
            params: this.filterset,
          }).then(res => {
              this.proposals = [...this.proposals, ...res.data.results];
              this.totalItems = this.proposals.count;
          }).finally(() => (this.loading = false))
        }
      }).finally(() => {this.loading = false;
      this.proposals.forEach((proposal) => {
        proposal.all_keys =Array.from(
          new Set([...Object.keys(proposal.assigned_dtos_by_type), ...Object.keys(proposal.pending_dtos_by_type), ...Object.keys(proposal.consumed_dtos_by_type)])
        )
      });
      })
      
    },
    loadOrganization(){
      this.$apiRequest('get', 'organizations/admin/', {}).then(res => {
        this.organization = res.data;
      })
    },
    innerParseFloat(float){
      return formatter.format(float)
    }
  },
  watch: {
    filterset: {
      handler (newValue, oldValue) {
        if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
          this.$nextTick(this.fetch);
        }
      },
    },
  },
}
</script>

<style scoped>
.striped >>> tbody > tr:nth-of-type(even) {
  background-color: #f8f8f8;
}
.striped >>> tbody > tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
