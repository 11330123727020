<template>
  <v-card :loading="loading" class="mx-auto" max-width="700"  tile>
    <v-img max-height="200" style="object-fit: cover; object-position: center;" src="@/assets/img/ttt.jpg"></v-img>
    <v-col>
      <div class="d-flex align-center justify-space-between">
        <v-avatar icon="$vuetify" size="63">
          <img src="https://www.w3schools.com/howto/img_avatar.png" alt="John">
        </v-avatar>
        <div class = "mr-5">
        <v-icon class="ml-5" v-if="admin">
        {{ "mdi-account-cog" }}
        </v-icon>
        <v-icon class="ml-5"  v-if="superAdmin">
          {{ "mdi-account-star" }}
        </v-icon>
        </div>
      </div>
      <v-list-item color="rgba(0, 0, 0, .4)">
        <v-list-item-content>
          <v-list-item-title class="title">{{name}} {{surname}}</v-list-item-title>
          <v-list-item-subtitle class="ml-3"> Username: {{ username }}</v-list-item-subtitle> 
          <v-list-item-subtitle class="ml-3"> Mail: {{ mail }}</v-list-item-subtitle> 
        </v-list-item-content>
        <v-btn  text x-small class="mb-5" color="primary" @click="showChangeName = !showChangeName">
          <span v-if="!showChangeName">{{ $t("Change profile name") }}</span>
          <span v-else>{{ $t("Hide change profile options") }}</span>
          <!-- <v-icon class="mx-2" small>{{ showChangeName ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> -->
        </v-btn>
      </v-list-item>
      <v-expand-transition>
        <div class="mt-2" v-show="showChangeName ">
          <div class="d-flex flex-column align-start pl-10">
            <v-text-field
                      style="max-width: 250px; max-height: 500px;"
                      v-model="name"
                      append-icon="mdi-account"
                      :label="$t('Name')"
                      placeholder=" "
                      persistent-placeholder
                      type="text"
                      outlined
            ></v-text-field>
            <v-text-field
                      style="max-width: 250px; max-height: 500px;"
                      v-model="surname"
                      append-icon="mdi-account"
                      :label="$t('Surname')"
                      placeholder=" "
                      persistent-placeholder
                      type="text"
                      outlined
            ></v-text-field>
            <v-text-field
                      style="max-width: 250px; max-height: 500px;"
                      v-model="mail"
                      append-icon="mdi-account"
                      :label="$t('Mail')"
                      placeholder=" "
                      persistent-placeholder
                      type="text"
                      outlined
            ></v-text-field>
            <v-btn  class="mb-5 mr-6 align-self-end" color="primary" @click="modifyUserFields()">
              Save changes
            </v-btn>
          </div>
        </div>
      </v-expand-transition>
      <h3> Organizations </h3>
      <v-list-item color="rgba(0, 0, 0, .4)" v-for="(item,index) in organizations" :key="index">
        <v-list-item-content>
          <div class="d-flex align-center">
            <v-icon class="mr-2">
              {{ item.icon }}
            </v-icon>
            <div>
              <v-list-item-title class="title">{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.token }}</v-list-item-subtitle>
            </div>
            <div class="ml-auto mb-4" ><v-btn color="primary" @click="copyLink(item.token)">Copy Registration Link</v-btn></div>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-col>    
  </v-card>
</template>

<script>
  export default {
    name: 'UserProfileView',
    data () {
      return {
        showChangeName: false,
        name: "Nombre",
        surname: "Apellido",
        mail:"mail@mail.com",
        username:"",
        admin:true,
        superAdmin:true,
        user:null,
        organizations:[],
        loading:false,
        items: [
          {
            icon: 'mdi-account',
            title: 'Light Bridges Instituto de astrogidics',
            subtitle: 'Token: OG912',
          },
          {
            icon: 'mdi-account-cog',
            title: 'Dark Matter Institute',
            subtitle: 'Token: DM123',
          },
          {
            icon: 'mdi-account',
            title: 'Quantum Physics Lab',
            subtitle: 'Token: QP456',
          },
          {
            icon: 'mdi-account-cog',
            title: 'Astrobiology Research Center',
            subtitle: 'Token: AR789',
          },
        ],   
      }
    },
    mounted() {
      this.user = this.$store.state.auth.user;
      let localStorageUser = JSON.parse(localStorage.getItem('user'));
      if (localStorageUser && this.user.username === localStorageUser.username) {
        this.user = localStorageUser
      }
      this.name = this.user.first_name ? this.user.first_name : "Name"
      this.surname = this.user.last_name ? this.user.last_name : "Surname"
      this.mail = this.user.email
      this.admin = this.user.is_staff 
      this.superAdmin = this.user.is_superuser 
      this.username = this.user.username
      console.log(this.superAdmin, this.user.is_superuser == true,"superuser")
      console.log(this.user.mail,this.user.first_name, this.user.last_name,"user")
      this.$apiRequest('get', 'organizations/mine/').then((res) => {
          this.loading = false;
          this.organizations= res.data;
          this.organizations = this.organizations.map((organization) => ({
              icon: organization.is_admin? 'mdi-account-cog':'mdi-account',
              title: organization.name,
              token: organization.token
          }));
          console.log(this.organizations)
        })
    },
    methods: {
      copyLink(token) {
        const link = window.location.origin + '/register?token='+ token;
        const textArea = document.createElement("textarea");
        textArea.value = link;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand("copy");
          this.$store.dispatch('alert/alert', {
            type: "success",
            message: this.$t("Link copied to clipboard")
          });
        } catch (err) {
          console.error('Fallback: Could not copy text: ', err);
        }
        document.body.removeChild(textArea);
      },
      modifyUserFields() {
        this.loading = true
        this.$apiRequest('post', 'modify-user', {
          data: {
                user_name: this.name,
                user_surname:this.surname,
                user_mail:this.mail,
                username:this.username
          }
        }).then((res) => {
          this.loading = false;
          console.log(res.status) 
          if (res.status == 200){
            this.user.first_name = this.name
            this.user.last_name = this.surname
            this.user.email= this.mail
            this.$store.dispatch('auth/updateUser', this.user);
            this.$store.dispatch('alert/alert', {
                    type: "info",
                    message: this.$t("User has been successfully modified")
            });
          } 
        }).catch(()=>{
          this.loading = false
        })
      }
    },
  }
</script>
